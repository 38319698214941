import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';

import PostList from '../components/PostList';
import Title from '../components/Title';

const IndexPage = ({ data }) => (
  <Layout pagetitle="Engineering leader, dad, boardgame geek and taster of freshly baked goods">
    <PageHeader>
      <Title type="subtitle">
        <strong>Software development</strong>, <strong>leadership</strong> and{' '}
        <strong>pop culture</strong> intersect here. I'm{' '}
        <strong>Josh Studley</strong> - an engineering leader, dad, boardgame
        geek and taster of freshly baked goods. This is my corner of the
        internet. Welcome.
      </Title>
    </PageHeader>
    <PostList posts={data.allSanityPost.nodes} />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allSanityPost(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        _id
        categories {
          title
        }
        slug {
          current
        }
        title
      }
    }
  }
`;
